import React, {useContext} from 'react';
import StartRequestForm from "../components/StartRequestForm";
import Header from "../components/Header";
import {ViewType} from "../const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {IconButton, Typography} from "@mui/material";
import AppContext from "../AppContext";
import Wrapper from "../components/Wrapper";

const StartRequestView = () => {
  const { updateView } = useContext(AppContext);
  return <>
    <Header>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        onClick={() => updateView(ViewType.Reports)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography sx={{ flexGrow: 1}} variant="h5">Початкова заявка</Typography>
    </Header>
    <Wrapper>
      <StartRequestForm />
    </Wrapper>
  </>
};

export default StartRequestView;
