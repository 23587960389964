import React, {useContext} from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import AppContext from "./AppContext";
import {ViewType} from "./const";
import ConfigView from "./views/ConfigView";
import ReportView from "./views/ReportView";
import StartRequestView from "./views/StartRequestView";
import ChecklistView from "./views/ChecklistView";

import './styles.css';

function App() {
  const { view } = useContext(AppContext);

  return <>
    <CssBaseline />
    {view === ViewType.Config && <ConfigView />}
    {view === ViewType.Reports && <ReportView />}
    {view === ViewType.startRequest && <StartRequestView />}
    {view === ViewType.Checklist && <ChecklistView />}
  </>;
}

export default App;
