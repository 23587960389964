import React, {useContext} from 'react';
import AppContext from "../AppContext";
import Wrapper from "../components/Wrapper";
import Header from "../components/Header";
import {ViewType} from "../const";
import {IconButton, Typography} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Checklist from "../components/Checklist";

const ChecklistView = () => {
  const { config, updateView } = useContext(AppContext);

  return <>
    <Header>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        sx={{ mr: 2 }}
        onClick={() => updateView(config?.crewComposition.length ? ViewType.Reports : ViewType.Config)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography sx={{ flexGrow: 1}} variant="h5">Чекліст</Typography>
    </Header>
    <Wrapper>
      <Checklist />
    </Wrapper>
  </>;
};

export default ChecklistView;
