import {Target, TargetMapType} from "../types";

const convertTargetsToTargetMap = (targets: Target[]): TargetMapType => {
  const result: TargetMapType = {};
  if(targets.length){
    targets.forEach(target => target?.description && Object.assign(result, { [target.description]: target.value}));
  }

  return result;
}

export default convertTargetsToTargetMap;
