import React, {useContext} from 'react';
import {Autocomplete, Button, styled, TextField} from "@mui/material";
import AppContext from "../AppContext";
import {useFormik} from "formik";
import {
  ammoConfig,
  detonatorOptions,
  ErrorMessages,
  ExtendedAmmos,
  extenderAmmosOptions,
  initiatorOptions,
  startRequestConfigInitialValues,
  ViewType
} from "../const";
import {parse, format, addDays} from "date-fns";
import {formatStartRequest} from "../utils/reportUtils";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {StartRequest} from "../types";
import InputMask from "react-input-mask";

const hhmmddMMyyRegExp = new RegExp(/^(?:[01]\d|2[0-3]):[0-5]\d (?:0[1-9]|[12]\d|3[01])\.(?:0[1-9]|1[0-2])\.\d{2}$/);

const startRequestValidationSchema = yup.object().shape({
  startDate: yup.string().required(ErrorMessages.Required).matches(hhmmddMMyyRegExp, { message: ErrorMessages.HHmmddMMyy}),
  endDate: yup.string().required(ErrorMessages.Required).matches(hhmmddMMyyRegExp, { message: ErrorMessages.HHmmddMMyy}),
  serialNumber: yup.string().required(ErrorMessages.Required),
  altitude: yup.string().required(ErrorMessages.Required),
  streamName: yup.string().required(ErrorMessages.Required),
  contactPhone: yup.string().required(ErrorMessages.Required),
});

type StartRequestFormValuesType = Omit<StartRequest, 'startDate' | 'endDate'> & {
  startDate: string;
  endDate: string;
};

const StartRequestForm = () => {
  const { config, startRequestConfig, updateStartRequestConfig, updateView } = useContext(AppContext);
  const {enqueueSnackbar} = useSnackbar();

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik<StartRequestFormValuesType>({
    initialValues: {
      ...(startRequestConfig || startRequestConfigInitialValues),
      startDate: startRequestConfig?.startDate ? format(startRequestConfig.startDate, 'HH:mm dd.MM.yy') : `__:__, ${format(new Date(), 'dd.MM.yy')}`,
      endDate: startRequestConfig?.endDate ? format(startRequestConfig.endDate, 'HH:mm dd.MM.yy') : `__:__, ${format(addDays(new Date(), 1), 'dd.MM.yy')}`,
    },
    validationSchema: startRequestValidationSchema,
    onSubmit: values => {
      let startDate, endDate;
      try {
        startDate = parse(values.startDate, 'HH:mm dd.MM.yy', new Date());
        endDate = parse(values.endDate, 'HH:mm dd.MM.yy', new Date());
      }catch(e){
      }

      const newStartRequestConfig = {
        ...values,
        startDate,
        endDate,
      }


      updateStartRequestConfig(newStartRequestConfig);
      const formatedStartRequest = formatStartRequest(config, newStartRequestConfig);
      navigator.clipboard.writeText(formatedStartRequest);
      enqueueSnackbar('Заявка скопійована до буферу обміну', { variant: "success" });
      updateView(ViewType.Reports);
    },
    enableReinitialize: true,
  });

  const handleChangeAmmo = (value: ExtendedAmmos[]) => {
    if(value.length > values.ammo.length){
      const config = ammoConfig[value[value.length - 1]];
      if(config && !values.initiators.includes(config.initiator)){
        setFieldValue('initiators', [...values.initiators, config.initiator]);
      }
      if(config && !values.detonators.includes(config.detonator)){
        setFieldValue('detonators', [...values.detonators, config.detonator]);
      }
    }
    setFieldValue('ammo', value || []);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99 99.99.99"
      value={values.startDate}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час початку"
        name="startDate"
        error={touched.startDate && Boolean(errors.startDate)}
        helperText={touched.startDate && errors.startDate}
      />}
    </InputMask>
    <InputMask
      mask="99:99 99.99.99"
      value={values.endDate}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete="off"
        label="Час закінчення"
        name="endDate"
        error={touched.endDate && Boolean(errors.endDate)}
        helperText={touched.endDate && errors.endDate}
      />}
    </InputMask>
    <StyledTextField
      label="Серійний номер"
      variant="outlined"
      name="serialNumber"
      value={values.serialNumber}
      onChange={handleChange}
      error={touched.serialNumber && Boolean(errors.serialNumber)}
      helperText={touched.serialNumber && errors.serialNumber}
    />
    <StyledTextField
      label="Висота"
      variant="outlined"
      name="altitude"
      value={values.altitude}
      onChange={handleChange}
      error={touched.altitude && Boolean(errors.altitude)}
      helperText={touched.altitude && errors.altitude}
    />
    <Autocomplete
      multiple
      value={values.ammo}
      onChange={(e, value) => handleChangeAmmo(value)}
      options={extenderAmmosOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="ammo"
        label="БК"
        error={touched.ammo && Boolean(errors.ammo)}
        helperText={touched.ammo && errors.ammo}
      />}
    />
    <Autocomplete
      multiple
      value={values.initiators}
      onChange={(e, value) => setFieldValue('initiators', value || [])}
      options={initiatorOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="initiators"
        label="Ініціатор"
        error={touched.initiators && Boolean(errors.initiators)}
        helperText={touched.initiators && errors.initiators}
      />}
    />
    <Autocomplete
      multiple
      value={values.detonators}
      onChange={(e, value) => setFieldValue('detonators', value || [])}
      options={detonatorOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="detonators"
        label="Детонатор"
        error={touched.detonators && Boolean(errors.detonators)}
        helperText={touched.detonators && errors.detonators}
      />}
    />
    <StyledTextField
      label="Контактний номер"
      variant="outlined"
      name="contactPhone"
      value={values.contactPhone}
      onChange={handleChange}
      error={touched.contactPhone && Boolean(errors.contactPhone)}
      helperText={touched.contactPhone && errors.contactPhone}
    />
    <StyledTextField
      label="Стрім"
      variant="outlined"
      name="streamName"
      value={values.streamName}
      onChange={handleChange}
      error={touched.streamName && Boolean(errors.streamName)}
      helperText={touched.streamName && errors.streamName}
    />
    <Button variant="contained" type="submit">Скопіювати заявку</Button>
  </StyledForm>
};

export default StartRequestForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;