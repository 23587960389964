import React, {createContext, useEffect, useState} from "react";
import {Config, Report, StartRequest, TargetMapType} from "./types";
import {
  checklistItems,
  configKey,
  defaultInitialTargetValue,
  reportsKey,
  startRequestConfigInitialValues, startRequestConfigKey,
  targetMapKey,
  ViewType
} from "./const";
import {configInitialValues} from "./components/ConfigForm/const";
import {updateAtIndex} from "./utils/arrayUtils";

type AppContextType = {
  startRequestConfig: StartRequest,
  updateStartRequestConfig: (startRequestConfig: StartRequest) => void;
  config: Config,
  updateConfig: (config: Config | null) => void,
  reports: Report[],
  updateReports: (reports: Report[]) => void,
  view: ViewType,
  updateView: (view: ViewType) => void,
  targetMap: TargetMapType,
  updateTargetMap: (newTargets: TargetMapType) => void,
  removeTargetFromMap: (key: string) => void,
  checklistState: boolean[];
  updateChecklistItem: (value: boolean, i: number) => void;
  resetChecklist: () => void;
}

const initValues = {
  startRequestConfig: startRequestConfigInitialValues,
  updateStartRequestConfig: () => {},
  config: configInitialValues,
  updateConfig: () => {},
  reports: [],
  updateReports: () => {},
  view: ViewType.Reports,
  updateView: () => {},
  targetMap: {},
  updateTargetMap: () => {},
  removeTargetFromMap: () => {},
  checklistState: [],
  updateChecklistItem: () => {},
  resetChecklist: () => {},
}

const AppContext = createContext<AppContextType>(initValues);

// @ts-ignore
export const AppProvider = ({ children }) => {
  const [startRequestConfig, setStartRequestConfig] = useState<StartRequest>(startRequestConfigInitialValues)
  const [config, setConfig] = useState<Config>(configInitialValues);
  const [reports, setReports] = useState<Report[]>([]);
  const [view, setView] = useState<ViewType>(ViewType.Reports);
  const [targetMap, setTargetMap] = useState<TargetMapType>({});
  const [checklistState, setChecklistState] = useState<boolean[]>((new Array(checklistItems.length)).fill(false));

  const updateConfig = (config: Config | null) => {
    setConfig(prevState => {
      const newConfig = config || {
        ...prevState,
        crew: configInitialValues.crew,
        crewComposition: configInitialValues.crewComposition,
      };
      localStorage.setItem(configKey, JSON.stringify(newConfig));
      return newConfig;
    });
    if(!config) {
      setView(ViewType.Config);
    }
  };

  const updateStartRequestConfig = (startRequestConfig: StartRequest) => {
    setStartRequestConfig(startRequestConfig);
    localStorage.setItem(startRequestConfigKey, JSON.stringify(startRequestConfig));
  }

  const updateReports = (reports: Report[]) => {
    setReports(reports);
    localStorage.setItem(reportsKey, JSON.stringify({ reports: reports}));
  }

  const updateView = (view: ViewType) => setView(view);

  const updateTargetMap = (targetMap: TargetMapType) => {
    setTargetMap(prevState => {
      const newMap = Object.assign(prevState, targetMap);
      localStorage.setItem(targetMapKey, JSON.stringify(newMap));
      return ({ ...newMap});
    });
  };

  const removeTargetFromMap = (key: string) => {
    setTargetMap(prevState => {
      const newMap = Object.assign(prevState, {});
      delete newMap[key];
      localStorage.setItem(targetMapKey, JSON.stringify(newMap));
      return ({...newMap});
    })
  }

  //init
  useEffect(() => {
    const serializedConfig = localStorage.getItem(configKey);
    if(serializedConfig){
      try {
        const savedConfig = JSON.parse(serializedConfig);
        if(savedConfig){
          setConfig({
            ...savedConfig,
            initialTargetValue: savedConfig.initialTargetValue || defaultInitialTargetValue,
          });
        }
        if(!savedConfig?.crewComposition?.length){
          updateView(ViewType.Config);
        }
      }catch(e){
        localStorage.removeItem(configKey);
      }
    }else{
      updateView(ViewType.Config);
    }

    const serializedStartRequestConfig = localStorage.getItem(startRequestConfigKey);
    if(serializedStartRequestConfig){
      try {
        const savedStartRequestConfig = JSON.parse(serializedStartRequestConfig);
        if(savedStartRequestConfig){
          setStartRequestConfig({
            ...savedStartRequestConfig,
            startDate: new Date(savedStartRequestConfig?.startDate),
            endDate: new Date(savedStartRequestConfig?.endDate),
          })
        }
      }catch (e) {
        localStorage.removeItem(startRequestConfigKey);
      }
    }

    const reportsSerialized = localStorage.getItem(reportsKey)
    if(reportsSerialized){
      try{
        const reportsObj = JSON.parse(reportsSerialized);
        setReports(reportsObj?.reports?.map((report: Report) => ({
          ...report,
          startTime: new Date(report?.startTime),
          endTime: new Date(report?.endTime),
          results: typeof report?.results?.[0] === 'string' ? report.results.map(result => ({ result: result, description: ''})) : report.results
        })) || []);
      }catch(e){
        localStorage.removeItem(reportsKey);
      }
    }

    const serializedTargetMap = localStorage.getItem(targetMapKey);
    if(serializedTargetMap){
      try {
        const targetMap = JSON.parse(serializedTargetMap);
        setTargetMap(targetMap || {})
      }catch (e) {
        localStorage.removeItem(targetMapKey)
      }
    }
  }, []);

  const updateChecklistItem = (value: boolean, i: number) => {
    setChecklistState(prevState => updateAtIndex(prevState, i, value));
  };

  const resetChecklist = () => {
    setChecklistState(prevState => prevState.map(() => false));
  }

  return <AppContext.Provider value={{
    startRequestConfig,
    updateStartRequestConfig,
    config,
    reports,
    updateConfig,
    updateReports,
    view,
    updateView,
    targetMap,
    updateTargetMap,
    removeTargetFromMap,
    checklistState,
    updateChecklistItem,
    resetChecklist,
  }}>{children}</AppContext.Provider>
};

export default AppContext;