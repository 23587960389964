import {StartRequest} from "./types";

export enum ErrorMessages {
  Required =  'Обов\'язкове поле',
  HHmm = 'Має бути у форматі гг:хх',
  HHmmddMMyyyy = 'Має бути у форматі гг:хх, дд.мм.рррр',
  HHmmddMMyy = 'Має бути у форматі гг:хх дд.мм.рр',
};

export const configKey = 'config';
export const reportsKey = 'reports_v4';
export const targetMapKey = 'targets';
export const startRequestConfigKey = 'startRequestConfig';

export const mgrsCoordinatesRegExp = /^\d{2}[A-Z] [A-Z]{2} \d{5} \d{5}$/;
export const mgrsCoordinatesStartRegExp = /^\d{2}[A-Z] [A-Z]{2}$/;

export enum ViewType {
  startRequest = 'Start request',
  Reports = 'Reports',
  Config = 'Config',
  Checklist = 'Checklist',
}

export enum Detonators {
  StandBy = 'Штатний',
  MYV1 = 'МУВ1',
  AMA = 'АМ-А',
  MD2 = 'МД-2',
  MD5M = 'МД-5М',
  KD8A = 'КД-8А',
  EDP = 'ЕДП-Р',
  NoContact = 'Неконтактний',
}

export const detonatorOptions = Object.values(Detonators);

export enum Initiators {
  StandBy = 'Штатний',
  InerticShockWorker = 'Інерційний накольник',
  ShockWorker = 'Накольник',
  MUV1 = 'МУВ-1',
  MVCH = 'МВЧ',
  Djonik = 'Джонік'
}

export const initiatorOptions = Object.values(Initiators);

// export const ammoOptions = ['ТМ-62', 'МОА-400', 'МОА-900', 'АЗБ-5М', 'ФАБ-400', 'ФАБ-900', 'ПФМ'];
export enum Ammos {
  TM62 = 'ТМ 62М',
  MOA400 = 'МОА-400',
  MOA900 = 'МОА-900',
  //AZB5M = 'АЗБ-5М',
  ZAB25C = 'ЗАБ-2.5С',
  FAB85 = 'ФАБ-8.5-А',
  OGB = 'ОГ-Б1',
  PFM = 'ПФМ',
  PTM1 = 'ПТМ-1Г',
  PTM3 = 'ПТМ-3',
}

export const ammoOptions = Object.values(Ammos);

type AmmoConfig = {
  [x: string]: {
    initiator: Initiators,
    detonator: Detonators,
  }
}
export const ammoConfig: AmmoConfig = {
  [Ammos.TM62]: {
    initiator: Initiators.InerticShockWorker,
    detonator: Detonators.MD2,
  },
  [Ammos.MOA400]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.MOA900]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.FAB85]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  //[Ammos.AZB5M]: {
  //  initiator: Initiators.ShockWorker,
  //  detonator: Detonators.AMA,
  //},
  [Ammos.PFM]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  },
  [Ammos.ZAB25C]: {
    initiator: Initiators.MUV1,
    detonator: Detonators.MD5M,
  },
  [Ammos.OGB]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  },
  [Ammos.PTM1]: {
    initiator: Initiators.Djonik,
    detonator: Detonators.StandBy,
  },
  [Ammos.PTM3]: {
    initiator: Initiators.Djonik,
    detonator: Detonators.StandBy,
  }
}

export enum Objectives {
  Mining = 'Мінування',
  HumanitarianCargo = 'Доставка провізії',
  HumanitarianCargo1 = 'Гуманітарний вантаж',
  Hit = 'Ураження',
  MetalHed = 'Скидання металевих їжаків',
  Barrage = 'Загородження з МПП',
}

export const objectiveOptions = Object.values(Objectives);

export enum Results {
  Executed = 'Виконано',
  Hit = 'Уражено',
  Fired = 'Обстріляно',
  Destroyed = 'Знищено',
  Missed = 'Невлучання',
  NoDetonation = 'Без детонації',
  DontHit = 'Неуражено',
  Mined = 'Заміновано',
  EmergencyLanding = 'Аварійна посадка',
  NotExecuted = 'Не виконано',
  Lost = 'Борт втрачено',
}
export const resultOptions = Object.values(Results);

export const defaultInitialTargetValue = '36T XT';
export const initialAmmoValue = {
  ammoType: '',
  ammoQuantity: '',
  detonator: '',
  detonatorQuantity: '',
  initiator: '',
  initiatorQuantity: '',
}

enum AdditionalAmmos {
  Barrage = Objectives.Barrage
}

export type ExtendedAmmos = Ammos | AdditionalAmmos;
export const ExtendedAmmos = { ...Ammos, ...AdditionalAmmos}

export const extenderAmmosOptions = Object.values(ExtendedAmmos)

export const checklistItems = [
  'Борт',
  'Батареї',
  'Пульти',
  'Шнур до пульту',
  'Штурманський планшет',
  'Планшет кропива',
  'Ноут для трансляцій',
  'Кабелі до планшету',
  'Телефон',
  'Щогла',
  'Підставка до антени',
  'Антена',
  'Кабель антени',
  'Піктейли',
  'Старлінки',
  'Перехідник старлінк',
  'Паверстейшен',
  'Павербанк',
  'Решітка',
  'Рації',
  'Пончо',
  'Викрутка',
  'Скоби',
  'Стяжки',
  'Детонатори',
  'Цукорок',
  'АКБ до РЕБ',
  'Цукорок до РЕБу',
];

export const startRequestConfigInitialValues: StartRequest = {
  startDate: undefined,
  endDate: undefined,
  serialNumber: '',
  altitude: '',
  streamName: '',
  contactPhone: '',
  initiators: [],
  detonators: [],
  ammo: [],
}