import React, {useContext, useEffect, useRef, useState} from 'react';
import {Autocomplete, IconButton, styled, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InputMask from 'react-input-mask';
import {mgrsCoordinatesRegExp} from "../const";
import {FormikErrors} from "formik";
import {Target} from "../types";
import AppContext from "../AppContext";
import CloseIcon from "@mui/icons-material/Close";
import UnstyledButton from "./ui/UnstyledButton";
import {createFilterOptions} from "@mui/material/Autocomplete";

interface TargetInputProps {
  target: string;
  targetDescription: string;
  setFieldValue: any;
  error?: string | FormikErrors<Target>;
  touched?: boolean;
  index: number;
  isLast: boolean;
  addTarget: () => void;
  removeTarget: () => void;
  isHumanitarianCargo: boolean;
}

type OptionType = {
  optionLabel: string;
  value: string;
  target?: string;
}

const filter = createFilterOptions<OptionType>();

const TargetInput: React.FC<TargetInputProps> = ({
  target,
  targetDescription,
  setFieldValue,
  error,
  touched,
  index,
  isLast,
  addTarget,
  removeTarget,
  isHumanitarianCargo,
                                                 }) => {

  const descriptionRef = useRef<HTMLInputElement>(null);
  const isFirst = index === 0;

  const { targetMap, removeTargetFromMap } = useContext(AppContext);
  const [dropdownValue, setDropdownValue ] = useState<OptionType | null>(null);
  const [options, setOptions] = useState<OptionType[]>([]);

  const handleRemoveSavedTarget = (key: string) => {
    removeTargetFromMap(key);
  };

  useEffect(() => {
    setOptions(Object.entries(targetMap).map(([key, value]) => ({
      optionLabel: `${key}: ${value}`,
      value: key,
      target: value,
    })))
  }, [targetMap]);

  useEffect(() => {
    if(!targetDescription){
      setDropdownValue(null);
    }else {
      const optionsValue = options.find(item => item.value === targetDescription && item.target === target)
      setDropdownValue(optionsValue || {
        value: targetDescription,
        optionLabel: targetDescription,
      })
    }
  }, [targetDescription, target, options]);

  return <Wrapper>
    <TargetWrapper>
      <InputMask
        mask="99a aa 99999 99999"
        value={target}
        onChange={(e) => {
          const val = e.target.value?.toUpperCase();
          setFieldValue(`targets[${index}].value`, val);
          if(val?.match(mgrsCoordinatesRegExp)) {
            descriptionRef?.current?.focus();
          }
        }
        }
      >
        {/*@ts-ignore*/}
        {(inputProps) => <TargetTextField
          {...inputProps}
          autoComplete='off'
          name={`targets[${index}].value`}
          label={`Ціль${isFirst && isLast ? '' : ' '+(index+1)}`}
          error={touched && Boolean(error)}
          helperText={touched && error}
        />}
      </InputMask>
      {!isFirst ? <IconButton
        size="large"
        color="inherit"
        onClick={removeTarget}
      >
        <RemoveIcon />
      </IconButton> : <BtnPlaceholder />}
      {isLast ? <IconButton
        size="large"
        color="inherit"
        onClick={addTarget}
      >
        <AddIcon />
      </IconButton> : <BtnPlaceholder />}
    </TargetWrapper>
    {isHumanitarianCargo ? <Autocomplete
      freeSolo
      clearOnBlur
      onChange={(e, value) => {
        if(value && typeof value === 'object'){
          value.target && setFieldValue(`targets[${index}].value`, value.target);
          setFieldValue(`targets[${index}].description`, value.value);
          setDropdownValue(value);
        }else if(typeof value === 'string'){
          setDropdownValue({
            value,
            optionLabel: value,
          });
          setFieldValue(`targets[${index}].description`, value);
        }else if(!value){
          setFieldValue(`targets[${index}].description`, '');
        }
      }}
      value={dropdownValue}
      getOptionLabel={option => typeof option === 'object' ? option?.value : option}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.value);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            value: inputValue,
            optionLabel: `Додати "${inputValue}"`,
          });
        }

        return filtered;
      }}
        options={options}
        renderInput={props => <DescriptionTextField
          {...props}
          autoComplete='off'
          label="Опис цілі"
          name={`targets[${index}].description`}
          value={targetDescription}
          onChange={(e) => setFieldValue(`targets[${index}].description`, e.target.value)}
          inputRef={descriptionRef}
        />}
      renderOption={(props, option) => {
        const { key, ...optionProps} = props;
        return <StyledAutocompleteOption key={key} {...optionProps}>
          <span>{option.optionLabel}</span>
          {option.target && <Button onClick={(e) => {
            e.stopPropagation();
            handleRemoveSavedTarget(option.value);
          }}><CloseIcon /></Button>}
        </StyledAutocompleteOption>
      }}
      />
      : <DescriptionTextField
      autoComplete="off"
      label="Опис цілі"
      name={`targets[${index}].description`}
      value={targetDescription}
      onChange={(e) => setFieldValue(`targets[${index}].description`, e.target.value)}
      inputRef={descriptionRef}
    />}
  </Wrapper>
};

export default TargetInput;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: ${({theme}) => theme.spacing(4)};
`;
const TargetWrapper = styled('div')`
  display: flex;
  margin-bottom: ${({theme}) => theme.spacing(4)};
`;
const TargetTextField = styled(TextField)`
  flex: 1;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;
const BtnPlaceholder = styled('div')`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;
const DescriptionTextField = styled(TextField)`
  flex: 1;
`;
const StyledAutocompleteOption = styled('li')`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const Button = styled(UnstyledButton)`
  padding: ${({ theme }) => theme.spacing(1)};
  width: 32px;
  height: 32px;
  cursor: pointer;
`;